export const LocalStorageDataModel = {
	AUTH_TOKEN: "TaxmannAuthorization",
	REFRESH_AUTH_TOKEN:'Refreshtoken',
	PREVIOUS_URL: "PREVIOUS_URL",
	CONTINUE_URL: "CONTINUE_URL",
	EMAIL: "email",
	USER_NAME: "userName",
	TYPE_FROM: "typefrom",
	CHECKOUT_INFO: "chekoutInfo",
	CONTENT_TOKEN: 'contenttoken',
	REMEMBER_ME: 'remember_me',
	PASSWORD: 'password',
	LOGIN_TYPE: 'loginType',
	SOCIAL_TYPE: 'socialType',
	REWARD_KEY: 'rewardKey',
	TREATY_COMPARATOR: 'treatyComparator',
	ACT_COMPARTOR: 'actComparator',
	RULE_COMPARATOR: 'ruleComparator',
	AAA_MODEL_REPORT: 'aaaModelReport',
	REGISTER_SOURCE: 'registerSource',
	USER_IP: 'userIp',
	VIEWED_DOCUMENTS: 'viewedDocuments',
	RESEARCH_SIDEBAR_TOGGLE: 'researchSideBarToggle',
	E_WAY_BILL_DATA: 'ewayBillData',
	BEPS_CALC_DATA: 'bepsCalcData',
	IND_AS_APPLICABILTY_DATA: 'indAsApplicabilityData',
	LEASE_CALC_DATA: 'leaseCalcData',
	AS_DEF_CALC_DATA: 'asDefCalcData',
	INDAS_DEF_CALC_DATA: 'indasDefCalcData',
	I_READER_TOKEN: 'ireadertoken',
	I_READER_DARK_THEME: 'ireaderDarktheme',
	PIN_CONFIRMATION_DATA: 'pinConfirmationData',
	SOCIAL_LOGIN_FAILED: 'social_login_failed',
	IS_RESEARCH_AUTO_SAVE: 'isResearchAutoSave',
	DROPBOX_AUTH_CODE: 'dropboxAuthCode',
	FIRST_TIME_VISIT: 'firstTimeVisit',
	FIRST_TIME_VISIT_CASE_LAW: 'firstTimeVisitCaseLaw',
	FIRST_TIME_VISIT_BOX: 'firstTimeVisitResearchBox',
	SHOW_NOTICE_BOX: 'showNoticeBox',
	FEEDBACK_OLD_SITE: 'feedbackOldSite',
	I_READER_COOKIE: 'ireadercookies',
	I_READER_USER_TOKEN_COOKIE: 'userToken',
	I_READER_USER_NAME_COOKIE: 'userName',
	I_READER_USER_EMAIL_COOKIE: 'userEmail',
	I_READER_USER_IPUSER_COOKIE: 'isIpUser',
	COUNTRY_CODE: 'countryCode',
	EXAM_COOKIE: 'ibcexamuserinfo',
	SHIPING_PIN_CODE: 'shipingPinCode',
	I_READER_COOKIE_TAXMANN: 'ireadercookiesTaxmann',
	IS_PROXY_USER: 'isProxyUser',
	ISIPUSER: 'isIpUser',
	NOTIFICATION_ALERT: 'notificationalert',
	BUDGET_FEEDBACK: 'budgetFeedback',
	IPUSERLOGIN: 'ipUserLogin',
	TRIAL_EMAIL: 'trialEmail',
	USER_DOMAIN: 'userDomain',
	DEALER_REFCODE_ADDED_IN_CART: 'dealerRefcodeAddedInCart',
	DEALER_PRODUCTS_ADD_TO_CART: 'dealerProductsAddToCart',
	DEALER_PRODUCTS_FOUND: 'dealerProductsFound',
	MEMBERSHIP_DETAILS: 'membershipDetails',
	CDKey: 'cdkey',
	MULTIPLE_EMAILS_ASSOCIATED: 'multipleEmailsAssociated',
	USER_MOBILE_NUMBER: 'userMobileNumber',
	USER_MOBILE_ID: 'userMobileId',
	USER_MOBILE_OTP: 'userMobileOTP',
	REGISTERED_USER: 'registeredUser',
	MOBILE_OTP_VERIFICATION_COMPLETED: 'mobileOTPVerificationCompleted',
	USER_MOBILE_COUNTRY_CODE: 'userMobileCountryCode',
	USER_MOBILE_EMAIL_ID: 'emailOtpId',
	INTERNAL_REFRERRER_URL: 'internalReferrerUrl',
	REGISTRATION_REFERRER: 'registrationReferrer',
	PAYMENT_REFERRER: 'paymentReferrer',
	LAST_ACCESS_BASE_URL_BY_USER: 'last_access_base_url',
	PRACTICE_FREE_TRIAL_ACTION: 'practice_free_trail_action_enable',
	PRACTICE_STRIP_VIEW: 'practice_strip_view',
	PRACTICE_FIRST_TIME_VISIT: 'practicefirsttimevisit',
	PRACTICE_NOTIFYME_SIGUP_BUTTON_CLICK: 'practiceNotifymeSignupButtonClick',
	UTM_SORUCE: 'utmSource',
	UTM_MEDIUM: 'utmMedium',
	UTM_CAMPAIGN: 'utmCampaign',
	RESEARCH_FREE_TRIAL_ACTION: 'research_trial_action_enable',
	UTM_CID: "utmCid",
	UTM_STATSID: "utmStatsId",
	UTM_URL: "utmUrl",
	UTM_URL_SOURCENAME: 'sourcename',
	WEBSITE_REFERER: 'websitereferer',
	COUNTRY_LIST: 'countrylist',
	IS_FROM_LOGIN:'isFromLogin',
	FIRST_NAME: 'fname',
	LAST_NAME: 'lname',
	MOBILE_NUMBER: 'mobno',
	ACADEMY_REF_CODE:'academy_refcode',
	ACTION_PRACTICE_LAUNCH_MODULE: 'actionPracticeLaunchModule',
	// EMAIL : 'emai'
	PRACTICE_MONTHLY : 'practice-monthly',
	PRACTICE_YEARLY_SELECTED_PLAN_DISCOVER_TOPICS: 'practiceYearlySelectedPlanDiscoverTopics',
	ENDMILE_EVENTS_CID_NUMBER:'endmileEventsCidNumber',
	NEWSLETTER_CONSENT_FLAG : 'newsletterConsentFlags',
	DISPLAY_CONSENT_FORM:'displayNewsletterConsentform',
	CLOSECOUNT:'closeCount',
	NETCORE_EVENT_TRACK:'netcoreEventTrack',
	RENEW_RESEARCH_PLAN: 'isRenewResearchPlan',
	BOOKSTORE_CA_CS_ROUTE : 'bookstoreCACSRoute',
	ACTIVE_CAMPAIGN:'activeCampaign',
	ACTIVE_CAMPAIGN_URL:'activeCampaignUrl',
	MACHINE_ID: 'machineId',
	MACHINE_ID_NEW: 'machineId1',
	CHECKOUT_DATA:'checkoutData',
	PPC_PRACTICE_INTERESTED_MODULES_DATA:'ppcPracticeInterestedModulesData',
	PPC_RESEARCH_INDIVIDUAL_INTERESTED_MODULES_DATA:'ppcResearchIndividualInterestedModulesData',
	PPC_RESEARCH_COMBO_INTERESTED_MODULES_DATA:'ppcResearchcomboInterestedModulesData',
	EXTRA_NETCORE_PAYLOAD:'extraNetCorePayload'
}
