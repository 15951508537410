// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`. - NOT IN OUR CASE
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: true,
	local: {
		BASE_URL: "https://lbackoffice.taxmann.com/taxmannapimysql/api/",
		NODE_DASHBOARD_BASE_URL: "https://devapi.taxmann.dev/dashboard/api/v1/",
		NODE_BOOKSTORE_BASE_URL: "https://devapi.taxmann.dev/bookstore/api/v1/",
		REDIS_URL: "https://nodedev.taxmann.com/research_module/", //mctl
		VIRTUAL_BOOK_URL: "https://lbackoffice.taxmann.com/taxmannapimysql/api/",
		FLIP_BOOK_BACKEND_URL: 'https://virtualbooks.taxmann.com/flipbooks/',
		FLIP_VIRTUAL_JOURNAL_URL: "https://virtualjournal.taxmann.com/preprod/",
		ENCRYPTION_DETAILS: {
			KEY: '0123456789abcdef0123456789abcdef'
		},
		IMAGE_URL: 'https://d3lzbbhyvqc4k.cloudfront.net/images/',
		WEBP_IMAGE_URL: 'https://d3lzbbhyvqc4k.cloudfront.net/images-webp/',
		SOCIAL_LOGIN_KEY: {
			FB_KEY: '480736533127001',
			GOOGLE_KEY: '536159705875-ds404ef5dgeunjjokgt0lcrg7927ic95.apps.googleusercontent.com'
		},
		VIRTUAL_EBOOK_API_URL: 'https://devapi.taxmann.dev/vebookbackend/api/v1/',
		VIRTUAL_EBOOK_LOCAL_API_URL: 'http://localhost:5004/',
		TAX_PRACTICE_URL: "https://taxpracticeapi.taxmann.com/taxPractice/",
		WEBSITE_URL: "https://lsupport.taxmann.com/",
		CENTAX_URL: "https://centaxapi.taxmann.com/",
		BuzzSproutToken:"520024bb63eea86aeef6441a087e3deb",
		MARKETING_API:"https://devapi.taxmann.dev/marketing/api/v1/",
		ACADEMY_API:"https://academyapi.taxmann.com/academy/",
		CRM_SERVER_URL: "https://devapi.taxmann.dev/msc/api/v1/",
		NETCORE_REGISTER_KEY : "cc58a9636208aa14ca3e0ce72952fd31", 
		CALENDER_SERVER:"https://stagapi.taxmann.dev/msc/api/v1/calendar/",
		NEW_DASHBOARD_SERVER:"https://devapi.taxmann.dev/dashboard/api/v1/",
 		NEW_BOOKSTORE:'https://devapi.taxmann.dev/bookstore/api/v1/',
		ENDMILE_API_SERVER:'https://devapi.taxmann.dev/msc/api/v1/endmile/',
		STUDENTS_SERVER:'https://devapi.taxmann.dev/students/api/v1/',
		STUDENTS_RAZORPAY_KEY : "rzp_test_xcUBXX9Y0gr2Iu",
		Student_Base_URL: "https://localapi.taxmann.com/api/",
		DOWNLOAD_PDF_URL: "https://pdf.taxmann.com/",
		AI_ADMIN_URL:"https://devapi.taxmann.dev/aiadmin/api/v1/",
		SCCRET_KEY_MACID: 'dsdgjSWDhjKEYGEN232xsdf5Asf5Op'
	},
	beta: {
		BASE_URL: "https://stagingapi.taxmann.com/api/", // preprod
		NODE_DASHBOARD_BASE_URL: "https://preprodapi.taxmann.com/bookstore-dashboard/", // preprod
		NODE_BOOKSTORE_BASE_URL: "https://preprodapi.taxmann.com/bookstore",
		REDIS_URL: "https://testapi.taxmann.com/research_module/", //preprod
		VIRTUAL_BOOK_URL: "https://stagingapi.taxmann.com/api/",
		FLIP_BOOK_BACKEND_URL: 'https://virtualbooks.taxmann.com/flipbooks/',
		FLIP_VIRTUAL_JOURNAL_URL: "https://virtualjournal.taxmann.com/preprod/",
		ENCRYPTION_DETAILS: {
			KEY: '0123456789abcdef0123456789abcdef'
		},
		IMAGE_URL: 'https://d3lzbbhyvqc4k.cloudfront.net/images/',
		WEBP_IMAGE_URL: 'https://d3lzbbhyvqc4k.cloudfront.net/images-webp/',
		SOCIAL_LOGIN_KEY: {
			FB_KEY: '652397832161755',
			GOOGLE_KEY: '536159705875-ds404ef5dgeunjjokgt0lcrg7927ic95.apps.googleusercontent.com'
		},
		VIRTUAL_EBOOK_API_URL: 'https://stagapi.taxmann.dev/vebookbackend/api/v1/',
		TAX_PRACTICE_URL: "https://practicestaging.taxmann.com/taxPractice/",
		WEBSITE_URL: "https://preprod.taxmann.com/",
		CENTAX_URL:"https://stagapi.centaxonline.com/",
		BuzzSproutToken:"520024bb63eea86aeef6441a087e3deb",
		MARKETING_API:"https://stagapi.taxmann.dev/marketing/api/v1/",
		ACADEMY_API: "https://testapi.taxmann.com/academy/",
		CRM_SERVER_URL: "https://stagapi.taxmann.dev/msc/api/v1/",
		NETCORE_REGISTER_KEY : "cc58a9636208aa14ca3e0ce72952fd31",  // qa.taxmann.com register key
		CALENDER_SERVER:"https://stagapi.taxmann.dev/msc/api/v1/calendar/",
		NEW_DASHBOARD_SERVER:"https://preprodapi.taxmann.com/bookstore-dashboard/",
		NEW_BOOKSTORE:'https://preprodapi.taxmann.com/bookstore/',
		ENDMILE_API_SERVER:'https://devapi.taxmann.dev/msc/api/v1/endmile/',
		STUDENTS_SERVER:'https://preprodapi.taxmann.com/students/',
		STUDENTS_RAZORPAY_KEY : "rzp_test_xcUBXX9Y0gr2Iu",
		Student_Base_URL: "https://stagingapi.taxmann.com/api/",
		DOWNLOAD_PDF_URL: "https://pdf.taxmann.com/",
		AI_ADMIN_URL:"",
		SCCRET_KEY_MACID: 'dsdgjSWDhjKEYGEN232xsdf5Asf5Op'
	},
	prod: {
		BASE_URL: "https://liveresearchapi.taxmann.com/api/",
		NODE_DASHBOARD_BASE_URL: "https://prodapibookstore.taxmann.com/bookstore-dashboard/",
		NODE_BOOKSTORE_BASE_URL: "https://prodapibookstore.taxmann.com/bookstore",
		REDIS_URL: "https://api.taxmann.com/research_module/",
		VIRTUAL_BOOK_URL: "https://liveresearchapi.taxmann.com/api/",
		FLIP_BOOK_BACKEND_URL: 'https://virtualbooks.taxmann.com/flipbooks/',
		FLIP_VIRTUAL_JOURNAL_URL: "https://virtualjournal.taxmann.com/",
		ENCRYPTION_DETAILS: {
			KEY: '789456123abcdef012456890ghijkl'
		},
		IMAGE_URL: 'https://d3lzbbhyvqc4k.cloudfront.net/images/',
		WEBP_IMAGE_URL: 'https://d3lzbbhyvqc4k.cloudfront.net/images-webp/',
		SOCIAL_LOGIN_KEY: {
			FB_KEY: '652397832161755',
			GOOGLE_KEY: '913663498867-kt5ilkol455kq1b0ei5qebhn0nnn9qhr.apps.googleusercontent.com'
		},
		VIRTUAL_EBOOK_API_URL: 'https://prodapi.taxmann.com/vebookbackend/api/v1/',
		TAX_PRACTICE_URL: "https://practiceprod.taxmann.com/taxPractice/",
		WEBSITE_URL: "https://www.taxmann.com/",
		CENTAX_URL:"https://prodapi.centaxonline.com/",
		BuzzSproutToken:"520024bb63eea86aeef6441a087e3deb",
		MARKETING_API:"https://prodapi.taxmann.com/marketing/api/v1/",
		ACADEMY_API: "https://api.taxmann.com/academy/",
		CRM_SERVER_URL: "https://prodapi.taxmann.com/msc/api/v1/",
		NETCORE_REGISTER_KEY : "0ec5ef80e56cf82f8a6b71d2bb8d5cca",
		CALENDER_SERVER:"https://stagapi.taxmann.dev/msc/api/v1/calendar/",
		NEW_DASHBOARD_SERVER:"https://prodapibookstore.taxmann.com/bookstore-dashboard/",
		NEW_BOOKSTORE:'https://prodapibookstore.taxmann.com/bookstore/',
 		ENDMILE_API_SERVER:'https://stagapi.taxmann.dev/msc/api/v1/endmile/',
		STUDENTS_SERVER:'https://prodapistudents.taxmann.com/students/',
		STUDENTS_RAZORPAY_KEY : "rzp_live_TgCQA3ByuAKrcq",
		Student_Base_URL: "https://liveresearchapi.taxmann.com/api/",
		DOWNLOAD_PDF_URL: "https://pdf.taxmann.com/",
		AI_ADMIN_URL:"",
		SCCRET_KEY_MACID: 'dsdgjSWDhjKEYGEN232xsdf5Asf5Op'
	}
};