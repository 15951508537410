import { IHeaderMenuList } from 'src/interfaces/interface';
import { ResearchCategoryUrl } from 'src/constants/constant';

export class HeaderMenuList {
	static list: IHeaderMenuList[] = [
		{
			label: 'Offering',
			id: 'products',
			child: [
				{
					label: 'Research',
					url: null,
					id: 'research',
					child: [
						{
							label: 'Latest Stories',
							url: '/research'
						},
						{
							label: 'Income Tax',
							url: '/research/' + ResearchCategoryUrl.DTL
						},
						{
							label: 'GST',
							url: '/research/' + ResearchCategoryUrl.GST
						},
						{
							label: 'Company Law',
							url: '/research/' + ResearchCategoryUrl.COMPANY_LAW
						},
						{
							label: 'International Tax',
							url: '/research/' + ResearchCategoryUrl.ILT
						},
						{
							label: 'Transfer Pricing',
							url: '/research/' + ResearchCategoryUrl.TP
						},
						{
							label: 'IBC',
							url: '/research/' + ResearchCategoryUrl.IBC
						},
						{
							label: 'FEMA, Banking & Insurance',
							url: '/research/' + ResearchCategoryUrl.FEMA
						},
						{
							label: 'Competition Law',
							url: '/research/' + ResearchCategoryUrl.COMPETiTION_LAW
						},
						{
							label: 'Account and Audit',
							url: '/research/' + ResearchCategoryUrl.AAA
						},
						{
							label: 'Indian Acts',
							url: '/research/' + ResearchCategoryUrl.INDIAN_ACTS
						},
					]

				},

				{
					label: 'Practice',
					id: 'practice',
					//externalUrl: 'https://virtualbooks.taxmann.com'
					url: '/practice'
				},
				{
					label: 'Advisory',
					id: 'advisory',
					externalUrl: '/advisory',
					sameTab: true
				},
				{
					label: 'Academy',
					id: 'academy',
					externalUrl: '/academy',
					sameTab: true
				},
				{
					label: 'Compliance',
					id: 'compliance',
					url: '/compliance-etds'
				},
				{
					label: 'Bookstore',
					id: 'bookstore',
					externalUrl: '/bookstore',
					sameTab: true
				},
				{
					label: 'Students',
					id: 'students',
					externalUrl: '/students',
					sameTab: true
				},
				//{
				//	label: 'Virtual Books & Journals',
				//	id: 'virtualbooks',
					//externalUrl: 'https://virtualbooks.taxmann.com'
				//	url: '/virtualbooks'
				//},
				// {
				// 	label: 'Exams',
				// 	id: 'exams',
				// 	externalUrl: 'https://exams.taxmann.com/'
				// },
				{
					label: 'Webinars',
					id: 'webinars',
					url: '/webinars'
				},
				{
					label: 'Podcast',
					id: 'podcast',
					url: '/podcast'
				},
				{
					label: 'Blog',
					id: 'blog',
					externalUrl: 'https://www.taxmann.com/post/blog/'
				}

			],
			specialLabel: null
		},
		/* {
			label: 'Bookstore',
			url: '/bookstore',
			id: 'bookstore',
		}, */
		{
			label: 'Advisory',
			externalUrl: '/advisory',
			specialLabel: 'NEW',
			sameTab: true,
			id: 'advisory',
		},
		{
			label: 'Academy',
			externalUrl: '/academy',
			specialLabel: 'NEW',
			sameTab: true,
			id: 'academy',
		},

		{
			label: 'Pricing',
			url: null,
			id: 'pricing',
			child: [
				{
					label: 'Research',
					url: '/research-pricing/combo-plans'
				},
				{
					label: 'Practice',
					url: '/practice-pricing'
				},
				{
					label: 'Advisory',
					externalUrl: '/advisory/pricing',
					sameTab: true
				}
			]
		},
		{
			label: 'Company',
			url: null,
			id: 'company',
			child: [
				{
					label: 'About Us',
					url: '/aboutus'
				},
				{
					label: 'Careers',
					externalUrl: '/careers'
				},
				{
					label: 'Media Coverage',
					externalUrl: 'https://www.taxmann.com/post/press-release'
				},
				{
					label: 'Blog',
					externalUrl: 'https://www.taxmann.com/post/blog/'
				}
			]
		},
		{
			label: 'Support',
			url: null,
			id: 'support',
			child: [
				{
					label: 'FAQs',
					url: '/faqs'
				},
				{
					label: 'Downloads',
					url: '/downloads'
				},
				{
					label: 'CD Key Activation',
					externalUrl: "https://support.taxmann.com/onesolution-cd-key-activation-code.aspx"
				},
				{
					label: 'Contact Us',
					url: '/contactus'
				}
			]
		}
	];
}
