export const TaxPracticeAPiUrl = {
	//customize user's feed API url
	GET_CUSTOMIZE_USER_FEED_DATA: 'getCustomizeUsersFeeds',

	//insert follow topics from customize user's feed page API url
	INSERT_FOLLOW_TOPICS_BY_USER: 'insertFollowTopicsByUser',

	//get all feeds
	GET_ALL_FEEDS: 'getTaxpracticeAllFeed',

	//get user's my feeds url
	GET_MY_FEEDS: 'getTaxpracticeMyFeed',

	//get trending topics url
	GET_TRENDING_TOPICS: 'getTrendingTopics',

	//get tax-practice all-feeds filters url
	GET_TAX_PRACTICE_ALL_FEED_FILTERS: 'getTaxpracticeAllFeedFilters',

	//get tax-practice user my feeds filters url
	GET_TAX_PRACTICE_MY_FEED_FILTERS: 'getTaxpracticeMyFeedFilters',

	//get discover topics url for view one
	GET_DISCOVER_TOPICS_VIEW_ONE: 'getDiscoverTopicsDataViewOne',

	//get discover topics url for view one
	GET_DISCOVER_TOPICS_VIEW_TWO: 'getDiscoverGlobalTopicsViewTwo',

	//get discover topics filters url
	GET_DISCOVER_TOPICS_FILTERS: 'getDiscoverTopicsFilters',

	UNFOLLOW_TOPICS: 'unfollowTopicsByUser',

	GET_READ_STORY: 'getReadStory',

	GET_TAB_INFO: 'getTabInfo',

	GET_ABOUT_THE_TOPIC: 'getAboutTheTopic',

	GET_BREAKING_NEWS: 'getBreakingNews',

	GET_QUICK_INFO: 'getQuickInfo',

	GET_RELATED_DOCUMENT_DATA: 'getRelatedDoc',

	//get left-panel data url
	GET_LEFT_PANEL_DATA: 'getLeftPanelData',

	INSERT_DOCUMENTS_POPULARITY: 'insertDocumentsPopularity',

	GET_TRENDING_STORIES: 'getTrendingStories',

	GLOBAL_TAXPRACTICE_SUGGESTIONS: 'getPracticeSuggestion',

	INSERT_USER_SEARCH_HISTORY: 'insertSearchUserHistory',

	GET_USER_SEARCH_HISTORY: 'getUserSearchHistory',

	GET_SEARCH_RESULTS: 'getSearchResult',

	GET_MORE_FILTERS_FOR_SEARCH_RESULTS: 'getSearchResultFilters',

	GET_DOC_INFO_URL: 'getDocInfo',

	GET_FOOT_NOTE_URL: 'getFootNote',

	GET_GLOBAL_TOPIC_COUNT_FOLLOW_BY_USER: 'getTotalGlobalTopicFollowedByUser',

	HTML_TO_RTF: 'htmlToRtf',

	DELETE_FROM_SEARCH_HISTORT: 'deleteFromUserSearchHistory',

	SINGLE_USER_PRICING_PLAN: 'pricing/practice',

	FREE_TRIAL_LOGIN_VERIFY: 'login/verifyemail',

	FREE_TRAIL_AFTER_REGISTRATION: 'practice/postFreeTrialPractice',

	FAQ_API: 'support/faqs',

	PRACTICE_HTML_TO_PDF: 'getFilehtmlTopdf',

	// get new topics url
	GET_NEW_TOPICS: 'latestTopics',
 // get recommended story or topic
   GET_RECOMMENDED: 'recommendedDocs',

   CHECK_SUBSCRIPTION : 'practice/checkTaxPracticeTrial',

   CHECK_FREE_TRIAL: 'practice/checkExistingSubscriptionPractice',
   PRACTICE_BUNDLED_DISCOUNT:'pricing/specialDiscount',
   GET_MY_FEED_UPDATE_COUNT: 'getMyFeedUpdateCount',
   POST_ADDRESS_PPC:'postAddressPPC',
   REGISTER_PPC_USER:'registerUserByPPC',
   // check email registered or not
   CHECK_EMAIL_EXIST: "auth/registration/CheckEmailExists",

	//Practie BOT
	CREATE_CHAT: 'create-chat',
	CHAT_LIST: 'chat-history',
	REMOVE_CHAT: 'remove-chat',
	RENAME_CHAT: 'update-chat-name',
	GET_CHAT: 'get-chats',
	QUERY_SUGGESTION :'query-suggestion',
	QUERY: 'query',
	FEEDBACK: 'feedback',
	CHECK_AUTH: 'checkAuth',
	LIMIT_REQUEST: 'limit-request',
	PIN_CHAT: 'pin-chat',
	CHECK_LIMIT: 'check-limit',
	USER_EVENT: 'userEvent',
	SEND_FEEDBACK_EMAIL: 'sendFeedbackEmail'
}
