import {
	ActsDataType,
	RulesDataType,
	FinanceActView,
	ResearchBoxFlowType,
} from "src/enums/enum";

export class ModalType {
	static get LARGE() {
		return "modal-lg";
	}
	static get XXLARGE() {
		return "modal-xl";
	}
	static get MEDIUM() {
		return "modal-md";
	}
	static get SMALL() {
		return "modal-sm";
	}
}

export const SocialLoginType = {
	GOOGLE: "google",
	FACEBOOK: "facebook",
};

export const constants = {
	onlyNumbers: /^[0-9]*$/,
	onlyAlphabets: /^[a-zA-Z ]*$/,
	mobileNumber: /^[1-9]{1}[0-9]{9}$/,
	PAN: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
	GSTIN: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
	//password: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
	password: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
	numbersWithDecimal: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
	emailPattern:
		/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z](?:[a-z]*[a-z])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i,
	splitBySpaceHypens: /[ -]+/,
	sharableBookStoreUrlSeparator: "-|-",
	mobile: /^[1-9]{1}[0-9]*$/,
	alphabets: /^[a-zA-Z.'()& ]*$/,
	onlyOneNumber: /^[0-9]{1}$/,
	firstNumberNotZero: /^[1-9][0-9]*$/,
	//	emailPattern :/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
	//	emailPattern :/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
	alphabetNumbers: /^[a-zA-Z0-9.'()&“‘’ ]*$/,
	alphabetNumbersComma: /^[a-zA-Z0-9.,'()&“‘’ ]*$/,
};

export const CheckoutFrom = {
	CART: "C",
	BUY_NOW: "B",
};

export const PublishEvents = {
	CART: "cart",
	ADD_TO_CART: "addToCart",
	UPADTE_CART: "updateCart",
	TRUE: "true",
	FALSE: "false",
	MOBILE_TOGGLE_ON: "mobileToggleOn",
	MOBILE_TOGGLE_OFF: "mobileToggleOff",
	ADDRESS: "address",
	ADDRESS_UPDATED: "addressUpdated",
	GSTIN_UPDATED: "gstinUpdated",
	GSTIN: "gstin",
	TOGGLE_NAV: "toggleNav",
	CONFIRM_DIALOG: "confirmDialog",
	BOOKSTORE_SEARCH_FILTER: "bookstoreSearchFilter",
	FILTER_TOGGLE: "filterToggle",
	BOOKSTORE_HEADER_HEIGHT: "headerHeight",
};

export const CalcFeildDataType = {
	NUMBER: "Number",
	RADIO_LIST: "Radio-List",
	CHECK_LIST: "Check-List",
	OPTION: "Option",
	CHECKBOX: "Checkbox",
	ACCORDIAN: "Accordian",
	DROPDOWN: "Dropdown",
	TEXT: "Text",
	CALENDAR: "Calendar",
};

export const ResearchGroupName = {
	ACT: "act",
	RULES: "rule",
	FORMS: "form",
	CASE_LAWS: "caselaws",
	CIRNOT: "cirnot",
	ARTICLES: "experts-opinion",
	COMMENTARY: "commentary",
	REPORT: "report",
	REPORTS: "reports",
	INFORMAL_REPORTS: "listinginformalreport",
	FAQ: "faqs",
	GST_TARRIF: "gst-tariff",
	CBDT: "cbdt",
	DTC: "dtc",
	DTL_PRACTICE: "dtl-practice",
	GST_PRACTICE: "gst-practice",
	PRACTICE_PROCEDURE: "practice-procedure",
	TREATIES: "dta",
	TAX_TREATY_COMMENTARIES: "tax-treaty-commentaries",
	ALL_ABOUT: "allabout",
	ALL_ABOUT_ILT: "all-about-ilt",
	ALL_ABOUT_AAA: "all-about-aaa",
	ACCOUNTING: "accounting",
	NFRA:'nfra',
	AUDITING: "auditing",
	FINANCIALS: "financials",
	DISCLOSURES: "disclosures",
	INDUSTRY_GUIDANCE: "industry-guidance",
	FINANCE_BILL: "finance-bill",
	FINANCE_ACT: "finance-act",
	REPEALED_ACT: "repealed-act",
	REPEALED_RULE: "repealed-rule",
	PARLIAMENT_ACT: "acts-of-parliament",
	BILLS: "bill",
	ORDINANCE: "ordinance",
	BEPS_ILT: "beps-ilt",
	MLI: "mli",
	CBIC: "cbic",
	HOME: "home",
	News: "News",
	DIGEST: "digest",
	ROUTE: "route",
	WHOLE_TREATY: "wholeTreaty",
	READY_RECONER: "ready-reconer",
	CGST: "central-gst",
	IGST: "integrated-gst",
	CENTRAL_GST: "cgst",
	INTEGRATED_GST: "igst",
	REPEALED_ACTS: "repealed-acts",
	REPEALED_RULES: "repealed-rules",
	CIR_NOT: "circular-notifications",
	TAX_PRACTICE: "tax-practice",
	TREATIES_TREATY: "treaties",
	BEPS_FAQ: "beps",
	MLI_FAQ: "mli",
	FAQS_IND_AS: "faq-ind-as-disclosure",
	SCHEDULE_THERE: "schedule-iii-disclosures",
	IND_AS_DISCLOSURE: "ind-as-disclosure-faq",
	SCHEDULE_THREE_DISCLOSURE: "schedule-iii-disclosures-as",
	GOODS_SERVICE_TAX_HOME: "sthome",
	CBDT_EXPLANATORY_NOTES: "cbdt-explanatory-notes",
	DIRECT_TAX_CODE:'direct-tax-code',
	READY_REFRENCER:'ready-referencer',
	TAX_TREATIES:'tax-treaties',
	COMMENTARIES: "commentaries",
	CIRS_NOTS: "circulars-notifications",
	REPORT_OTHERS:'reports-others'
	// also add in SubCategoryNameLabel
};

export const IFIlterDataType = {
	TEXT: "Text",
	NUMBER: "Number",
	CHECKBOX: "Checkbox",
	RADIO: "Radio",
	SINGLE_SELECT: "Single-Select",
	MULTI_SELECT: "Multi-Select",
	DATE: "Date",
	DATE_FUTURE: "Date-Future",
	DATE_PAST: "Date-Past",
	SQL: "sql",
	COMBINATION: "combination",
	AUTOCOMPLETE: "autocomplete",
	ASSESMENT_YEAR: "assement-year",
	AUTO_SUGGEST: "auto-suggest",
};

export const ResearchCategoryUrl = {
	ALL: "all",
	DTL: "direct-tax-laws",
	GST: "gst",
	ILT: "international-tax",
	TP: "transfer-pricing",
	COMPANY_LAW: "company-and-sebi",
	IBC: "ibc",
	AAA: "account-audit",
	INDIAN_ACTS: "indian-acts",
	COMPETiTION_LAW: "competition-law",
	VAT: "goods-services-tax",
	FEMA: "fema-banking-insurance",
	GST_NEW: "gst-new",
	INCOME_TAX:'income-tax',
	SEBI: 'all-about-securities-laws'
};

export const SQLFieldLabels = {
	1: "Starts With",
	2: "Ends With",
	3: "Contains",
	4: "Exact",
};

export const FileContentType = {
	HTML: "html",
	XML: "xml",
	HTM: "htm",
	PDF: "pdf",
};

export const ResearchListHasFile = {
	YES: "yes",
	NO: "no",
};

export const InstructionFileConst = "-Instructions";

export const ActsChapterFilter = [
	{
		id: ActsDataType.SECTION,
		label: "Section Wise",
	},
	{
		id: ActsDataType.CHAPTER,
		label: "Chapter Wise",
	},
];

export const RulesSubjectFilter = [
	{
		id: RulesDataType.RULE,
		label: "Rule Wise",
	},
	{
		id: RulesDataType.SUBJECT,
		label: "Subject Wise",
	},
];

export const AAAAccountingCategory = {
	IND_AS: "ind-as",
	AS: "accounting-standards",
	ICDS: "icds",
	CAS: "cost-accounting-standards",
	IND_GAS: "indian-gas",
	CASE_STUDIES:'case-studies'
};

export const AAAAuditingCategory = {
	AUDITING_STANDARDS: "auditing-standards",
	CARO: "caro",
	CAS: "cost-auditing-standards",
	SECRETARIAL_STANDARDS: "secretarial-standards",
	CS_AUDITING_STANDARD: "cs-auditing-standards",
};

export const AAANFRA = {
		IND_AS_OBSERVATION:'ind-as-observations',
		IND_AS_INTERPRETATION:'ind-as-interpretations',
		AS_OBSERVATIONS:'as-observations',
		AS_INTERPRETATION:'as-interpretations',
		SA_OBSERVATIONS:'sa-observations',
		SA_INTERPRETATION:'sa-interpretations'

}

export const AAAFinancialsCategory = {
	IND_AS_FINANCIALS: "ind-as",
	AS_FINANCIALS: "as",
	MODEL_FINANCIALS: "model-financials",
	STATUTORY_FINANACIALS: "statutory-financials",
	IND_AS_FINANCIALS_AAA: 'ind-as-financials'
};

export const AAADisclosuresCategory = {
	AS_DISCLOSURES: "as-disclosures",
	FAQ_IND_AS_DISCLOSURE: "faq-ind-as-disclosure",
	IND_AS_DISCLOSURE: "ind-as-disclosure",
	IND_AS_IMPACT: "ind-as-impact",
	SCHEDULE_3_DISCLOSURES: "schedule-iii-disclosures",
	SCHEDULE_3_DISCLOSURES_INDAS: "schedule-iii-disclosures-indas",
	SEBI_DISCLOSURES: "sebi-disclosures",
};

export const AllAAAConst = {
	IND_AS: "indas",
	AS: "as",
	SA: "sa"
};

export const FinanceActViewBy = [
	{
		id: FinanceActView.YEAR_WISE,
		label: "Year Wise",
	},
	{
		id: FinanceActView.IT_ACT,
		label: "Section Wise (Income tax act)",
	},
];

export const CategoryNameLabel = {
	"direct-tax-laws": "Income Tax",
	"gst-new": "GST",
	"international-tax": "International Tax",
	"transfer-pricing": "Transfer Pricing",
	"company-and-sebi": "Company Law",
	ibc: "Insolvency & Bankruptcy Code",
	"account-audit": "Account & Audit",
	"indian-acts": "Indian Acts",
	"fema-banking-insurance": "FEMA & Banking",
	"competition-law": "Competition Law",
	"goods-services-tax": "Excise/ST/VAT",
	repealed: "Repealed",
	gst: "GST",

	// other
	"integrated-gst": "IGST",
	"central-gst": "CGST",
	"compensation-gst": "Compensation Cess",
};

export const SubCategoryNameLabel = {
	act: "Act",
	rule: "Rule",
	form: "Forms",
	caselaws: "Case Laws",
	cirnot: "Circular & Notifications",
	"experts-opinion": "Articles",
	commentary: "Commentaries",
	report: "Reports",
	listinginformalreport: "Informal Guidance",
	faqs: "FAQs",
	"gst-tarrif": "GST Tarrif",
	cbdt: "CBDT",
	dtc: "DTC",
	"dtl-practice": "Income Tax Practice",
	"gst-practice": "GST Practice",
	"practice-procedure": "Practice & Procedure",
	dta: "Treaties",
	"tax-treaty-commentaries": "Commentaries",
	allabout: "All About",
	accounting: "Accounting",
	auditing: "Auditing",
	financials: "Financials",
	disclosures: "Disclosures",
	"industry-guidance": "Industry Guidance",
	"finance-bill": "Finance Bill",
	"finance-act": "Finance Act",
	"repealed-act": "Repealed Act",
	"repealed-rule": "Repealed Rule",
	"acts-of-parliament": "Acts Of Parliament",
	bill: "Bills",
	"beps-ilt": "BEPS",
	mli: "MLI",
	cbic: " CBIC/GSTC/GSTN ",
	home: "Home",
	digest: "Digest",

	// other labels
	"statutory-financials": "Statutory Financials",
	"model-financials": "Model Financials",
	"ind-as-financials": "Ind AS Financials",
	"auditing-standards": "Auditing Standards",
	caro: "CARO",
	"cost-auditing-standards": "Cost Auditing Standards",
	"secretarial-standards": "Secretarial Standards",
	"cs-auditing-standards": "CS Auditing Standards",
	"ind-as": "Ind AS",
	"accounting-standards": "AS",
	icds: "ICDS",
	"cost-accounting-standards": "Cost Accounting Standards",
	"indian-gas": "Indian GAS",
	"as-disclosures": "AS Disclosures",
	"ind-as-disclosure": "Ind AS Disclosures",
	"faq-ind-as-disclosure": "FAQ on Ind AS Disclosures",
	"ind-as-impact": "Ind AS Impact",
	"schedule-iii-disclosures": "Schedule III Disclosures AS",
	"schedule-iii-disclosures-indas": "Schedule III Disclosures Ind AS",
	"sebi-disclosures": "SEBI Disclosures",
	"ind-as-observations":"Ind AS Observation",
	"ind-as-interpretations":"Ind AS Interpretations",
	"as-observations":"AS Observation",
	"as-interpretations":"AS Interpretations",
	"sa-observations":"SA Observation",
	"sa-interpretations":"SA Interpretations",
	News: "News",
	"ibc-faqs": "FAQs",
	'case-studies': 'Case Studies'
};

export const ResearchBoxType = [
	{
		id: ResearchBoxFlowType.DOCUMENT,
		label: "Documents",
	},
	{
		id: ResearchBoxFlowType.VIEWS,
		label: "Saved Views",
	},
];

export const ResearchBoxFolderType = {
	SYSTEM: "SYSTEM",
	USER: "USER",
	FAVOURITE: "favourite",
	STICKY: "sticky",
	BOOKMARK: "bookmark",
};

export const ViewCategoryList = [
	{
		label: CategoryNameLabel[ResearchCategoryUrl.DTL],
		value: ResearchCategoryUrl.DTL,
	},
	{
		label: CategoryNameLabel[ResearchCategoryUrl.GST],
		value: ResearchCategoryUrl.GST,
	},
	{
		label: CategoryNameLabel[ResearchCategoryUrl.TP],
		value: ResearchCategoryUrl.TP,
	},
	{
		label: CategoryNameLabel[ResearchCategoryUrl.COMPANY_LAW],
		value: ResearchCategoryUrl.COMPANY_LAW,
	},
	{
		label: CategoryNameLabel[ResearchCategoryUrl.IBC],
		value: ResearchCategoryUrl.IBC,
	},
	{
		label: CategoryNameLabel[ResearchCategoryUrl.AAA],
		value: ResearchCategoryUrl.AAA,
	},
	{
		label: CategoryNameLabel[ResearchCategoryUrl.COMPETiTION_LAW],
		value: ResearchCategoryUrl.COMPETiTION_LAW,
	},
	{
		label: CategoryNameLabel[ResearchCategoryUrl.VAT],
		value: ResearchCategoryUrl.VAT,
	},
	{
		label: CategoryNameLabel[ResearchCategoryUrl.FEMA],
		value: ResearchCategoryUrl.FEMA,
	},
];

export const ViewSubCatList = [
	{
		label: SubCategoryNameLabel[ResearchGroupName.ACT],
		value: ResearchGroupName.ACT,
	},
	{
		label: SubCategoryNameLabel[ResearchGroupName.RULES],
		value: ResearchGroupName.RULES,
	},
	{
		label: SubCategoryNameLabel[ResearchGroupName.FORMS],
		value: ResearchGroupName.FORMS,
	},
	{
		label: SubCategoryNameLabel[ResearchGroupName.CASE_LAWS],
		value: ResearchGroupName.CASE_LAWS,
	},
	{
		label: SubCategoryNameLabel[ResearchGroupName.CIRNOT],
		value: ResearchGroupName.CIRNOT,
	},
	{
		label: SubCategoryNameLabel[ResearchGroupName.ARTICLES],
		value: ResearchGroupName.ARTICLES,
	},
	{
		label: SubCategoryNameLabel[ResearchGroupName.COMMENTARY],
		value: ResearchGroupName.COMMENTARY,
	},
	{
		label: SubCategoryNameLabel[ResearchGroupName.FAQ],
		value: ResearchGroupName.FAQ,
	},
	{
		label: SubCategoryNameLabel[ResearchGroupName.REPORT],
		value: ResearchGroupName.REPORT,
	},
	{
		label: SubCategoryNameLabel[ResearchGroupName.BEPS_ILT],
		value: ResearchGroupName.BEPS_ILT,
	},
	{
		label: SubCategoryNameLabel[ResearchGroupName.MLI],
		value: ResearchGroupName.MLI,
	},
];

export const ResearchCategoryList = [
	ResearchCategoryUrl.DTL,
	ResearchCategoryUrl.GST,
	ResearchCategoryUrl.ILT,
	ResearchCategoryUrl.TP,
	ResearchCategoryUrl.COMPANY_LAW,
	ResearchCategoryUrl.IBC,
	ResearchCategoryUrl.AAA,
	ResearchCategoryUrl.INDIAN_ACTS,
	ResearchCategoryUrl.COMPETiTION_LAW,
	ResearchCategoryUrl.VAT,
	ResearchCategoryUrl.FEMA,
	ResearchCategoryUrl.INCOME_TAX,
];

export const PasswordType = {
	TEXT: "text",
	PASSWORD: "password",
};

export const ApiStatus = {
	SUCCESS: "SUCCESS",
	ERROR: "ERROR",
	DOCUMENT_ACCESS_LIMIT_EXCEED: "DOCUMENT_ACCESS_LIMIT_EXCEED",
	SESSION_EXPIRED: "SESSION_EXPIRED",
	USER_ALREADY_REGISTERED: "USER_ALREADY_REGISTERED",
	OTP_NOT_VERIFIED: "OTP_NOT_VERIFIED",
	PLAN_NOT_SUBCRIBED: "PLAN_NOT_SUBCRIBED",
	DATA_NOT_FOUND: "DATA_NOT_FOUND",
	WARNING: "WARNING",
	DATA_FOUND: "DATA_FOUND",
	INVALID_KEY: "INVALID_KEY",
	USED_KEY: "USED_KEY",
	KEY_USAGE_LIMIT_EXCEED: "KEY_USAGE_LIMIT_EXCEED",
	KEY_EXPIRED: "KEY_EXPIRED",
	NOT_IP_USER: "NOT_IP_USER",
	MODULE_NOT_SUBSCRIBED: "MODULE_NOT_SUBSCRIBED",
	DATA_SAVED: "DATA_SAVED",
	ALREADY_PURCHASED: "ALREADY_PURCHASED",
	IREADER_ACCESS_LIMIT_EXCEED: "IREADER_ACCESS_LIMIT_EXCEED",
	TOKEN_INVALID: "TOKEN_INVALID",
	NOT_DELIVERABLE: "NOT_DELIVERABLE",
	USER_NOT_FOUND: "USER_NOT_FOUND",
	INCORRECT_PASSWORD: "INCORRECT_PASSWORD",
	INVALID_LOGIN: "INVALID_LOGIN",
	PLAN_EXPIRED: "PLAN_EXPIRED",
	PLAN_SUBCRIBED: "PLAN_SUBCRIBED",
	INVALID_CONTENT_TOKEN: "INVALID_CONTENT_TOKEN",
	INVALID_IREADER_TOKEN: "INVALID_IREADER_TOKEN",
	USER_CASHBACK_LIMIT_EXCEED: "USER_CASHBACK_LIMIT_EXCEED",
	FREE_COPY_ALREADY_INCART: "FREECOPYALREADYINCART",
	USER_REGISTERED: "USER_REGISTERED",
	OTP_SEND: "OTP_SEND",
	OTP_VERIFIED: "OTP_VERIFIED",
	IP_NOT_REGISTERED: "IP_NOT_REGISTERED",
	USER_MULTIPLE_EMAILS: "USER_MULTIPLE_EMAILS",
	USER_NOT_REGISTERED: "USER_NOT_REGISTERED",
	MOBILE_VERIFIED: "MOBILE_VERIFIED",
	INVALID_OTP: "INVALID_OTP",
	EMAIL_SENT: "EMAIL_SENT",
	USER_NOT_ACTIVE: "USER_NOT_ACTIVE",
	USER_PHONE_NOT_FOUND: "USER_PHONE_NOT_FOUND",
	API_CALLING_LIMIT_EXCEED: "API_CALLING_LIMIT_EXCEED",
	EXPIRED: "PLAN_EXPIRED",
	DATA_NOT_SAVED: "DATA_NOT_SAVED",
	DATA_ALREADY_EXISTS: "Data_Already_Exists",
	success:"success",
	EMAIL_NOT_VERIFIED : "EMAIL_NOT_VERIFIED",
	CHANGE_PASSWORD: "CHANGE_PASSWORD",
	SOCIAL_LOGIN_NOT_FOUND : "SOCIAL_LOGIN_NOT_FOUND",
	PLANACTIVATED: 'PLANACTIVATED',
	EMAIL_EXITS: 'EMAIL_EXITS',
	PLANALREADYACTIVATED:'PLANALREADYACTIVATED',
	EMAIL_ALREADY_VERIFIED:'EMAIL_ALREADY_VERIFIED',
	INVALID_EMAIL_OTP:'INVALID_EMAIL_OTP',
	PHONE_ALREADY_EXIST:'PHONE_ALREADY_EXIST',
	EMAIL_OTP_SENT: 'EMAIL_OTP_SENT',
	VERIFIED:'VERIFIED',
	EMAIL_EXISTS:'EMAIL_EXISTS',
	INVALID_CHARACTER:'invalid api request',
	INVALID_CHARACTER_ERROR_MESSAGE:'We do not support the following charterers: [!#$%^*:{}|<>]',
	PLAN_ACTIVATED_ON_OTHER_EMAIL: 'PLAN_ACTIVATED_ON_OTHER_EMAIL',
	EMAIL_MISMATCH:'EMAIL_MISMATCH'
};

export const OrderStatus = {
	IN_PROCESS: "In Process",
	DISPATCHED: "Dispatched",
	CANCELED: "Cancelled",
	DELIVERED: "Delivered",
	RETURNED: "Returned",
	ACTIVATED: "Activated",
	EXPIRED: "Expired",
};

export const StudentsOrderStatus = {
	ACCEPTED:"ACCEPTED",
	ACTIVATED: "ACTIVATED",
    IN_PROCESS: "PROCESSING",
	AWAITING_PICKUP:'AWAITING_PICKUP',
	SHIPPED: "SHIPPED",
	DELIVERED: "DELIVERED",
	PENDING: "PENDING",
	CANCELLED: "CANCELLED",
	RETURNED: "RETURNED",
	YET_TO_BE_DISP:"YET_TO_BE_DISP",
	YET_TO_BE_DISPATCHED:"YET_TO_BE_DISPATCHED",
	YET_TO_BE_ACTIVATED:"YET_TO_BE_ACTIVATED",
	READY_FOR_PICKUP:"READY_FOR_PICKUP",
	DEACTIVATED:"DEACTIVATED"
};

export const SubscriptionStatus = {
	ACTIVE: "Active",
	EXPIRE: "Expire",
};

export const ViabilityType = {
	FORWARD_REFERRENCE: "ForwardReference",
	CASE_REFERRED: "CaseReferred",
	AFFIRMED: "Affirmed",
};

export const ViablityTypeLabel = {
	ForwardReference: "Forward Reference",
	CaseReferred: "Case Referred",
};

export const SalutationDropdownList = [
	{
		label: "Mr.",
		value: "Mr.",
	},
	{
		label: "Mrs.",
		value: "Mrs.",
	},
	{
		label: "Miss.",
		value: "Miss.",
	},
	{
		label: "Ms.",
		value: "Ms.",
	},
	{
		label: "Dr.",
		value: "Dr.",
	},
	{
		label: "Prof.",
		value: "Prof.",
	},
	{
		label: "Sri.",
		value: "Sri.",
	},
	{
		label: "Smt.",
		value: "Smt.",
	},
];

export const WalletTransactionStatus = {
	DEBITED: "DEBITED",
	EXPIRED: "EXPIRED",
	CREDITED: "CREDITED",
};

export const CirnotSubGroup = {
	CIRCULAR: "circular",
	NOTIFICATION: "notification",
};

export const ArchiveNewsTag = {
	FEATURED_STORY: "fs",
	EDITORS_PICK: "ep",
};

export const AdvertisementPageList = {
	BOOKSTORE_HOME: "bookstore_home",
	RESEARCH_HOME: "research_home",
	DTL_HOME: "dtl_home",
	GST_HOME: "gst_home",
	COMPANY_AND_SEBI_HOME: "sebi_home",
	ILT_HOME: "ilt_home",
	TP_HOME: "tp_home",
	IBC_HOME: "ibc_home",
	FEMA_HOME: "fema_home",
	COMPETITION_HOME: "competition_home",
	AAA_HOME: "aaa_home",
	INDIAN_ACT_HOME: "indianact_home",
	ALL_ARCHIVES: "all_archives",
	DTL_ARCHIVES: "dtl_archives",
	GST_ARCHIVES: "gst_archives",
	COMPANY_AND_SEBI_ARCHIVES: "sebi_archives",
	ILT_ARCHIVES: "ilt_archives",
	TP_ARCHIVES: "tp_archives",
	IBC_ARCHIVES: "ibc_archives",
	FEMA_ARCHVES: "fema_archives",
	COMPETITION_ARCHIVES: "competition_archives",
	AAA_ARCHIVES: "aaa_archives",
	INDIAN_ACT_ARCHIVES: "indianact_archives",
	VIRTUAL_BOOK_HOME: "virtualbook_home",
	VIRTUAL_JOURNAL_HOME: "virtualjournal_home",
	MY_FEED: "my_feed",
	ALL_FEEDS: "all_feed",
	DISCOVER_TOPICS: "discover_topics",
	TAXPRACTICE_SEARCH_RESULT: "taxpractice_search_page",
	WEBINARS_HOME: "webinars",
};

export const AdvertisementPositionList = {
	TOP_BANNER: "topbanner",
	THIRD_PANEL: "thirdpanel",
};

export const FaqType = {
	RESEARCH: "website",
	BOOKSTORE: "bookstore",
	COMPLIANCE: "compliance",
	EXAMS: "exams",
	MOBILE: "mobileapp",
	VIRTUAL_BOOK: "virtualbook",
	PAYMENTS: "payments",
	EMI_PAYMENTS: 'emi',
	ONLINE_PAYMENTS: 'onlinepayments',
	DD_CHEQUE_PAYMENTS: 'ddcheque',
	NEFT_PAYMENTS: 'neft'
};

export const Currency = {
	INR: "INR",
	USD: "USD",
};

export const CurrencySymbol = {
	INR: "₹",
	USD: "$",
};

export const DeviceType = {
	MOBILE: "MOBILE",
	TABLET: "TABLET",
	DESKTOP: "DESKTOP",
	ANDROID: "ANDROID",
	MOBILE_WEB: "MOBILE_WEB",
};
export const WindowFeatures =
	"menubar=no,toolbar=no,location=no,resizable=yes,scrollbars=yes,status=no,width=1080,height=650";
export const ReaderChapterWiseList = {
	YES: "y",
	NO: "n",
};
export const ReaderBookType = {
	SECTION_BOOK: "SectionBook",
	BOOK: "Book",
	JOURNAL: "Journal",
	RULE_BOOK: "RuleBook",
};

export const UTMCamapignResearchUrl = {
	"direct-tax-laws": "Income-Tax",
	gst: "GST",
	"international-tax": "International-Tax",
	"transfer-pricing": "Transfer-Pricing",
	"company-and-sebi": "Company-Law",
	ibc: "IBC",
	"account-audit": "Account-Audit",
	"indian-acts": "Indian-Acts",
	"competition-law": "Competition-Law",
	"goods-services-tax": "",
	"fema-banking-insurance": "Fema-Banking-Insurance",
	home: "Home-page",
	"research-home": "Latest-Updates",
};

export const UTMSourceResearchUrl = {
	home: "Taxmann-Home-Page",
	"research-home": "Research-Home-Page",
};

export const RegisterPlatformType = {
	EXAM: "EXAM",
	BLOG: "BLOG",
	BOOKSTORE: "BOOKSTORE",
	COMPLIANCE: "COMPLIANCE",
	RESEARCH: "RESEARCH",
	VIRTUAL_BOOKS: "VIRTUAL_BOOKS",
	MEDIA: "MEDIA",
	HOME: "HOME",
	REGISTER: "REGISTER",
	OTHERS: "OTHERS",
	PRACTICE: "PRACTICE",
};

export const ExternalWebsiteURL = {
	EXAM: "exams",
	BLOG: "blog",
	BOOKSTORE: "bookstore",
	COMPLIANCE: "compliance-etds",
	RESEARCH: "research",
	VIRTUAL_BOOKS: "virtualbooks",
	MEDIA: "press-release",
	AUTH: "auth",
	PRACTICE: "practice",
};

export const DeviceTypeEnum = {
	WEB: 0,
	MOBILE_SITE: 1,
	MOBILE_APP: 2,
};

export const PaymentPlatformType = {
	BOOKSTORE: "BOOKSTORE",
	COMPLIANCE: "COMPLIANCE",
	RESEARCH: "RESEARCH",
	VIRTUAL_BOOK: "VIRTUAL_BOOK",
	RESEARCH_OFFLINE: "RESEARCH_OFFLINE",
	VIRTUAL_JOURNAL: "VIRTUAL_JOURNAL",
	ACADEMY: "ACADEMY",
};
export const PracticeCategoryId = {
	INCOME_TAX: "1",
};
export const PracticeTypeName = {
	READ: "READ",
	FAQS: "FAQS",
	FORMATS: "FORMATS",
	TUTORIALS: "TUTORIALS",
	Landmark_Rulings: "LANDMARK-RULINGS",
	ANNOUNCEMENTS: "ANNOUNCEMENTS",
	UPDATES: "UPDATES",
};

export const BookstoreProductName = {
	1:'BOOK',
	2:'DVD',
	3:'JOURNAL',
	4:'E_B0OK',
	5:'WEBSITE',
	6:'ONLINE_COURSE',
	7:'E_JOURNAL',
	8:'E_SERVICES',
	9:'GSP_SERVICE',
	10:'EXAMS',
	11:'VIRTUAL_BOOK',
	12:'VIRTUAL_JOURNAL',
}

export const taxmannContactDetails = {
	email: "students@taxmann.com",
	number: "011-40749922",
	address: "Address: 59/32 New Rohtak Road New Delhi – 110005	India",
  }

 export const Colors = {
	// order status text colors
	order_accepted: "#B230F2",
	order_activated: "#F2D330",
	order_processing: "#305BF2",
	order_awaiting_pickup: "#30CFF2",
	order_shipped: "#F2308D",
	order_delivered: "#30F238",
	order_pending: "#F29930",
	order_cancelled: "#F23030",
	order_returned: "#CC2929",
  
	// order status text background colors
	order_accepted_bg: "#F9F2FC",
	order_activated_bg: "#FCFBF2",
	order_processing_bg: "#F2F5FC",
	order_awaiting_pickup_bg: "#F2FBFC",
	order_shipped_bg: "#FCF2F7",
	order_delivered_bg: "#F2FCF3",
	order_pending_bg: "#FCF8F2",
	order_cancelled_bg: "#FCF2F2",
	order_returned_bg: "#FCF2F2",
  
	// item delivery status text color
	item_yet_to_be_dispatched: "#B230F2",
	item_activated: "#F2D330",
	item_yet_to_be_activated: "#F29930",
	item_processing: "#305BF2",
	item_ready_for_pickup: "#30CFF2",
	item_shipped: "#F2308D",
	item_delivered: "#30F238",
	item_cancelled: "#F23030",
	item_returned: "#CC2929",
	item_deactivated: "#991F1F",
  
	// item delivery status background color
	item_yet_to_be_dispatched_bg: "#F9F2FC",
	item_activated_bg: "#FCFBF2",
	item_yet_to_be_activated_bg: "#FCF8F2",
	item_processing_bg: "#F2F5FC",
	item_ready_for_pickup_bg: "#F2FBFC",
	item_shipped_bg: "#FCF2F7",
	item_delivered_bg: "#F2FCF3",
	item_cancelled_bg: "#FCF2F2",
	item_returned_bg: "#FCF2F2",
	item_deactivated_bg: "#FFEDEE",
  };
  
  export const getOrderStatusData = (orderStatus: String) => {
	let obj = {} as any;
	switch (orderStatus) {
	  // order status
	  case "ACCEPTED":
		obj = {
		  status: "Accepted",
		  color: colors.order_accepted,
		  bg: colors.order_accepted_bg,
		};
		break;
	  case "ACTIVATED":
		obj = {
		  status: "Activated",
		  color: colors.order_activated,
		  bg: colors.order_activated_bg,
		};
		break;
	  case "PROCESSING":
		obj = {
		  status: "Processing",
		  color: colors.order_processing,
		  bg: colors.order_processing_bg,
		};
		break;
	  case "AWAITING_PICKUP":
		obj = {
		  status: "Awaiting Pickup",
		  color: colors.order_awaiting_pickup,
		  bg: colors.order_awaiting_pickup_bg,
		};
		break;
	  case "SHIPPED":
		obj = {
		  status: "Shipped",
		  color: colors.order_shipped,
		  bg: colors.order_shipped_bg,
		};
		break;
	  case "DELIVERED":
		obj = {
		  status: "Delivered",
		  color: colors.order_delivered,
		  bg: colors.order_delivered_bg,
		};
		break;
	  case "PENDING":
		obj = {
		  status: "Pending",
		  color: colors.order_pending,
		  bg: colors.order_pending_bg,
		};
		break;
	  case "CANCELLED":
		obj = {
		  status: "Cancelled",
		  color: colors.order_cancelled,
		  bg: colors.order_cancelled_bg,
		};
		break;
	  case "RETURNED":
		obj = {
		  status: "Returned",
		  color: colors.order_returned,
		  bg: colors.order_returned_bg,
		};
		break;
  
	  // item status
	  case "YET_TO_BE_DISP":
		obj = {
		  status: "Yet to be Dispatched",
		  color: colors.item_yet_to_be_dispatched,
		  bg: colors.item_yet_to_be_dispatched_bg,
		};
		break;
	  case "YET_TO_BE_DISPATCHED":
		obj = {
		  status: "Yet to be Dispatched",
		  color: colors.item_yet_to_be_dispatched,
		  bg: colors.item_yet_to_be_dispatched_bg,
		};
		break;
	  case "YET_TO_BE_ACTIVATED":
		obj = {
		  status: "Yet to be Activated",
		  color: colors.item_yet_to_be_activated,
		  bg: colors.item_yet_to_be_activated_bg,
		};
		break;
	  case "READY_FOR_PICKUP":
		obj = {
		  status: "Ready for Pickup",
		  color: colors.item_ready_for_pickup,
		  bg: colors.item_ready_for_pickup_bg,
		};
		break;
	  case "DEACTIVATED":
		obj = {
		  status: "Deactivated",
		  color: colors.item_deactivated,
		  bg: colors.item_deactivated_bg,
		};
		break;
	  default:
		break;
	}
	return obj;
  };

  const colors = {
	// order status text colors
	order_accepted: "#B230F2",
	order_activated: "#F2D330",
	order_processing: "#305BF2",
	order_awaiting_pickup: "#30CFF2",
	order_shipped: "#F2308D",
	order_delivered: "#30F238",
	order_pending: "#F29930",
	order_cancelled: "#F23030",
	order_returned: "#CC2929",
  
	// order status text background colors
	order_accepted_bg: "#F9F2FC",
	order_activated_bg: "#FCFBF2",
	order_processing_bg: "#F2F5FC",
	order_awaiting_pickup_bg: "#F2FBFC",
	order_shipped_bg: "#FCF2F7",
	order_delivered_bg: "#F2FCF3",
	order_pending_bg: "#FCF8F2",
	order_cancelled_bg: "#FCF2F2",
	order_returned_bg: "#FCF2F2",
  
	// item delivery status text color
	item_yet_to_be_dispatched: "#B230F2",
	item_activated: "#F2D330",
	item_yet_to_be_activated: "#F29930",
	item_processing: "#305BF2",
	item_ready_for_pickup: "#30CFF2",
	item_shipped: "#F2308D",
	item_delivered: "#30F238",
	item_cancelled: "#F23030",
	item_returned: "#CC2929",
	item_deactivated: "#991F1F",
  
	// item delivery status background color
	item_yet_to_be_dispatched_bg: "#F9F2FC",
	item_activated_bg: "#FCFBF2",
	item_yet_to_be_activated_bg: "#FCF8F2",
	item_processing_bg: "#F2F5FC",
	item_ready_for_pickup_bg: "#F2FBFC",
	item_shipped_bg: "#FCF2F7",
	item_delivered_bg: "#F2FCF3",
	item_cancelled_bg: "#FCF2F2",
	item_returned_bg: "#FCF2F2",
	item_deactivated_bg: "#FFEDEE",
  };

  export enum PaymentModes {
	RAZORPAY = 1,
	CHEQUE_DD,
	NEFT,
	COD,
	WALLET_NO_PAY,
	SHOPSE,
	RAZORPAY_RECURRING,
  }
  
  export const AppRouteURL = {
	TRACK_ORDER: "/user/track-order",
	PAY_ONLINE: "/order/pay-online/",
	RETURN_CANCEL_TRACKING: "/user/track-return-cancel-order/",
	WRITE_REVIEW: "/write-review/",
	THANK_YOU_PURCHASE: "/payment-success/",
	PAYMENT_FAILED: "/payment-failed/", 
  }

  export const FormatTypeENUM:any = {
	"VIRTUAL": "virtualbooks",
	"HARD": "books",
	"POCKET" :"lectures",
	"HARDVIDEO" : "lectures",
	"HARD-VIDEO":"lectures"
  }

  export const BOOKSTORE_CATEGORY_NAME = {
	'direct-tax-laws': 'Direct Tax Laws'
  }
  
  export const REPORT_CASE_CONST = {
	caselaws: "1",
	cirnot: "2",
  }

  export const MONTH_NAME_CONST = {
	JANUARY: "January",
	FABRUARY: "February",
	MARCH: "March",
	APRIL: "April",
    MAY: "May",
	JUNE: "June", 
	JULY: "July", 
	AUGUST: "August", 
	SEPTEMBER: "September",
    OCTOBER: "October",
	NOVEMBER: "November", 
	DECEMBER: "December"
  }

  export const PLATFORM_REDIRECTION = {
    "/student" : "/students/",
	"/auth/forgotpassword": "/gp/auth/forgot-password",
	"/auth/register" : "/gp/auth/register",
	"/auth/login" : "/gp/auth/login",
	"/user/my-newsletter" : "/gp/user/my-newsletter"

  }
